// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["kTFBCUY3t", "uYE_do7F1"];

const serializationHash = "framer-BUwbM"

const variantClassNames = {kTFBCUY3t: "framer-v-hibxoo", uYE_do7F1: "framer-v-m0ld7e"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Bold: "bold", Duotone: "duotone", Fill: "fill", Light: "light", Regular: "regular", Thin: "thin"}

const humanReadableVariantMap = {Dark: "kTFBCUY3t", Light: "uYE_do7F1"}

const getProps = ({height, iconColor, iconDark, iconLight, id, weight, width, ...props}) => { return {...props, AeO5PLd9q: iconColor ?? props.AeO5PLd9q ?? "var(--token-fa6255fa-1747-4bc3-894a-8375bc25f0bd, rgb(28, 28, 28))", fle84VlOx: iconLight ?? props.fle84VlOx ?? "Sun", njtQOvFbC: humanReadableEnumMap[weight] ?? weight ?? props.njtQOvFbC ?? "bold", pdO6Nm4lI: iconDark ?? props.pdO6Nm4lI ?? "Moon", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kTFBCUY3t"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;iconColor?: string;iconDark?: string;iconLight?: string;weight?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, AeO5PLd9q, pdO6Nm4lI, fle84VlOx, njtQOvFbC, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kTFBCUY3t", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-hibxoo", className, classNames)} data-framer-name={"Dark"} layoutDependency={layoutDependency} layoutId={"kTFBCUY3t"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({uYE_do7F1: {"data-framer-name": "Light"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1ol7qdh-container"} layoutDependency={layoutDependency} layoutId={"hwcI4ZrnI-container"}><Phosphor color={AeO5PLd9q} height={"100%"} iconSearch={"House"} iconSelection={pdO6Nm4lI} id={"hwcI4ZrnI"} layoutId={"hwcI4ZrnI"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={njtQOvFbC} width={"100%"} {...addPropertyOverrides({uYE_do7F1: {iconSelection: fle84VlOx}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BUwbM.framer-6seae7, .framer-BUwbM .framer-6seae7 { display: block; }", ".framer-BUwbM.framer-hibxoo { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px; position: relative; width: min-content; }", ".framer-BUwbM .framer-1ol7qdh-container { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 27px); position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BUwbM.framer-hibxoo { gap: 0px; } .framer-BUwbM.framer-hibxoo > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-BUwbM.framer-hibxoo > :first-child { margin-left: 0px; } .framer-BUwbM.framer-hibxoo > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"uYE_do7F1":{"layout":["auto","auto"]}}}
 * @framerVariables {"AeO5PLd9q":"iconColor","pdO6Nm4lI":"iconDark","fle84VlOx":"iconLight","njtQOvFbC":"weight"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerI_1K6zwMv: React.ComponentType<Props> = withCSS(Component, css, "framer-BUwbM") as typeof Component;
export default FramerI_1K6zwMv;

FramerI_1K6zwMv.displayName = "ThemeSwitchMode";

FramerI_1K6zwMv.defaultProps = {height: 27, width: 24};

addPropertyControls(FramerI_1K6zwMv, {variant: {options: ["kTFBCUY3t", "uYE_do7F1"], optionTitles: ["Dark", "Light"], title: "Variant", type: ControlType.Enum}, AeO5PLd9q: {defaultValue: "var(--token-fa6255fa-1747-4bc3-894a-8375bc25f0bd, rgb(28, 28, 28)) /* {\"name\":\"font/active\"} */", title: "IconColor", type: ControlType.Color}, pdO6Nm4lI: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Moon", description: undefined, hidden: undefined, title: "IconDark"}, fle84VlOx: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Sun", description: undefined, hidden: undefined, title: "IconLight"}, njtQOvFbC: PhosphorControls?.["weight"] && {...PhosphorControls["weight"], defaultValue: "bold", description: undefined, hidden: undefined, title: "Weight"}} as any)

addFonts(FramerI_1K6zwMv, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})